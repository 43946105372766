import { Line } from 'vue-chartjs'

import chartMixin from '../mixins/chartMixin'
import ColorHash from '~/utils/Colors'

export default {
  name: 'LineChart',
  extends: Line,
  mixins: [chartMixin],
  props: {
    color: {
      type: [String, Number],
      default: null
    },
    interval: {
      type: String,
      required: true
    },
    showIncomplete: {
      type: Boolean,
      required: true
    },
    autoscaleAxis: {
      type: Boolean,
      required: true
    },
    tooltipText: {
      type: Array,
      required: true
    }
  },
  computed: {
    colorString() {
      switch (typeof this.color) {
        case 'string':
          return this.color
        case 'number':
          return this.config.line.colors[this.color]
        default:
          return ''
      }
    },
    chartOptions() {
      return {
        layout: {
          padding: 0
        }
        // hover: {
        //   mode: 'single',
        //   intersect: true
        // }
      }
    },
    chartData() {
      const { colors } = this.config.line

      // handle more than one dat
      const {
        complete: completedIndices,
        incomplete: incompletedIndices
      } = this.getCompletedIncompleteIndices()

      const getIndexedData = (dataset, startIndex, endIndex) => {
        return dataset.slice(startIndex, endIndex).map((value, index) => {
          return {
            x: this.labels[startIndex + index],
            y: value
          }
        })
      }

      let datasets = this.isMultipleDatasets ? this.data : [this.data]
      datasets = datasets.reduce((datasets, dataset, datasetIndex) => {
        let color = this.colorString || colors[datasetIndex]
        let options = this.getDatasetOptions(color, dataset.key)

        if (!dataset.visible) {
          return datasets
        }

        completedIndices.forEach(indices => {
          const data = getIndexedData(dataset.data, ...indices)
          datasets.push({ ...options, data, name: dataset.name })
        })
        if (this.showIncomplete) {
          const { r, g, b } = ColorHash.hexToRgb(color)
          const incompleteColor = `rgba(${r}, ${g}, ${b}, 0.5)`
          options = this.getDatasetOptions(incompleteColor, dataset.key)
          incompletedIndices.forEach(indices => {
            const data = getIndexedData(dataset.data, ...indices)
            datasets.push({
              ...options,
              data,
              borderDash: [5, 3, 2, 3],
              name: dataset.name
            })
          })
        }
        return datasets
      }, [])

      const labels = this.showIncomplete
        ? this.labels
        : this.removeIncompleteLabels(this.labels, datasets)

      this.setYAxisAutoscale()

      return {
        keepShowing: [],
        labels,
        datasets
      }
    },
    isMultipleDatasets() {
      return Array.isArray(this.data)
    }
  },
  beforeMount() {
    this.addPlugin({
      id: 'holdTooltip',
      beforeRender: function(chart) {
        let tooltipsToDisplay = chart.data.keepShowing
        chart.pluginTooltips = []

        tooltipsToDisplay.map(element => {
          let activeTooltip = []
          if (element.length > 1) {
            element.map(el => {
              const { hidden } = chart.getDatasetMeta(el.currentDatasetIndex)

              if (!hidden) {
                activeTooltip.push(
                  chart.getDatasetMeta(el.currentDatasetIndex).data[el.index]
                )
              }
            })
            chart.pluginTooltips.push(
              // eslint-disable-next-line
              new Chart.Tooltip(
                {
                  _chart: chart.chart,
                  _chartInstance: chart,
                  _data: chart.data,
                  _options: chart.options.tooltips,
                  _active: activeTooltip.filter(Boolean)
                },
                chart
              )
            )
            return
          }
          const { hidden } = chart.getDatasetMeta(element.currentDatasetIndex)

          if (!hidden) {
            activeTooltip.push(
              chart.getDatasetMeta(element.currentDatasetIndex).data[
                element.index
              ]
            )
          }

          chart.pluginTooltips.push(
            // eslint-disable-next-line
            new Chart.Tooltip(
              {
                _chart: chart.chart,
                _chartInstance: chart,
                _data: chart.data,
                _options: chart.options.tooltips,
                _active: activeTooltip.filter(Boolean)
              },
              chart
            )
          )
        })
      },
      afterDatasetsDraw: function(chart, easing) {
        // eslint-disable-next-line
        Chart.helpers.each(chart.pluginTooltips, function(tooltip) {
          tooltip.initialize()
          tooltip.update(true)
          tooltip.pivot()
          tooltip.transition(easing).draw()
        })
      }
    })

    if (this.options.gradient) {
      this.addGradient()
    }
  },
  methods: {
    setYAxisAutoscale() {
      this.options.scales.yAxes[0].ticks.beginAtZero = !this.autoscaleAxis
    },
    removeIncompleteLabels(allLabels, datasets) {
      const completeLabels = {}
      datasets.forEach(({ data }) => {
        data.forEach(({ x }) => {
          completeLabels[x] = true
        })
      })

      return allLabels.reduce((labels, label) => {
        if (completeLabels[label]) {
          labels.push(label)
        }
        return labels
      }, [])
    },
    getDatasetOptions(color, key) {
      const { point, line } = this.config

      return {
        dataKey: key,
        borderColor: color,
        borderWidth: line.width,
        backgroundColor: line.backgroundColor,
        lineTension: line.tension,
        pointBorderColor: point.border.color,
        pointRadius: point.radius,
        pointBackgroundColor: color,
        pointHoverBorderColor: point.hover.border.color,
        pointHoverBorderWidth: point.hover.border.width,
        pointHoverRadius: point.hover.radius,
        pointHitRadius: point.hover.radius + point.hover.border.width
      }
    },
    getCompletedIncompleteIndices() {
      const complete = []
      const incomplete = []

      if (this.labels.length === 0) {
        return { complete, incomplete }
      }

      let startIndex = 0
      let isPreviousComplete = this.$date.getIsCompleteInterval(
        ...this.labels[startIndex].split(' - ').map(date => parseInt(date)),
        this.interval
      )

      for (let i = 1; i < this.labels.length; i++) {
        const label = this.labels[i].split(' - ').map(date => parseInt(date))
        const isCurrentComplete = this.$date.getIsCompleteInterval(
          ...label,
          this.interval
        )
        if (isCurrentComplete === isPreviousComplete) {
          continue
        }

        if (isPreviousComplete) {
          complete.push([startIndex, i])
          i = i - 1
        } else {
          const endIndex = i === this.labels.length ? i : i + 1
          incomplete.push([startIndex, endIndex])
        }

        isPreviousComplete = isCurrentComplete
        startIndex = i
      }

      const list = isPreviousComplete ? complete : incomplete
      list.push([startIndex, this.labels.length])

      return { incomplete, complete }
    },

    addGradient() {
      const { colors } = this.config.line
      this.addPlugin({
        id: 'responsiveGradient',
        afterLayout(chart) {
          const dataset = chart.data.datasets[0]

          // Chart has not rendered yet (redirect to analytics from login)
          if (!dataset) {
            return
          }

          let minValue = Number.MAX_VALUE
          let maxValue = Number.MIN_VALUE

          for (let i = 0; i < dataset.data.length; ++i) {
            if (minValue > dataset.data[i]) minValue = dataset.data[i]
            if (maxValue < dataset.data[i]) maxValue = dataset.data[i]
          }

          // Calculate Y pixels for min and max values.
          const yAxis = chart.scales['y-axis-0']
          const minValueYPixel = yAxis.getPixelForValue(minValue)
          const maxValueYPixel = yAxis.getPixelForValue(maxValue)

          // Chart has not rendered yet
          if (minValueYPixel + maxValueYPixel < 0) {
            return
          }

          const gradient = chart.ctx.createLinearGradient(
            0,
            maxValueYPixel,
            0,
            minValueYPixel
          )
          const { r, g, b } = ColorHash.hexToRgb(colors[0])
          const rgb = [r, g, b].join(',')
          gradient.addColorStop(0, `rgba(${rgb},0.2)`)
          gradient.addColorStop(1, `rgba(${rgb},0)`)

          dataset.backgroundColor = gradient
        }
      })
    }
  }
}
