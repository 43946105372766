<template>
  <line-chart
    ref="lineChart"
    :color="color"
    :data="data"
    :labels="labels"
    :interval="currentInterval"
    :tooltip-text="tooltipText"
    :show-incomplete="displayEmpty"
    :autoscale-axis="autoscaleAxis"
    :options="options"/>
</template>
<script>
import LineChart from './LineChart'

export default {
  name: 'ShipmentsChart',
  components: {
    LineChart
  },
  props: {
    stat: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      required: true
    },
    labels: {
      type: Array,
      required: true
    },
    tooltipText: {
      type: Array,
      required: true
    },
    color: {
      type: [String, Number],
      default: null
    },
    gradient: {
      type: Boolean,
      default: false
    },
    interval: {
      type: String,
      required: true
    },
    displayEmpty: {
      type: Boolean,
      default: true
    },
    autoscaleAxis: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentInterval: ''
    }
  },
  computed: {
    options() {
      return {
        xAxisCallback: this.xAxisCallback.bind(this),
        tooltipCallback: this.tooltipCallback.bind(this),
        gradient: this.gradient,
        onClick: this.toogleTooltip,
        scales: {
          xAxes: [
            {
              ticks: {
                maxTicksLimit: this.currentInterval === 'days' ? 5 : 10,
                fontSize: 12
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: !this.autoscaleAxis
              }
            }
          ]
        }
      }
    }
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        // only update interval if data changed
        // otherwise the interval is changed first
        this.currentInterval = this.interval
      }
    }
  },
  methods: {
    toogleTooltip(event, activeElement) {
      const { _chart: chart } = this.$refs.lineChart.$data
      let keepShowing = chart.data.keepShowing

      if (Array.isArray(activeElement) && activeElement.length) {
        if (activeElement.length > 1) {
          if (
            keepShowing.some(element =>
              activeElement.every(
                (el, index) =>
                  element.length === activeElement.length &&
                  el._index === element[index].index &&
                  el._datasetIndex === element[index].currentDatasetIndex
              )
            )
          ) {
            let index = keepShowing.findIndex(element =>
              activeElement.every(
                (el, index) =>
                  element.length === activeElement.length &&
                  el._index === element[index].index &&
                  el._datasetIndex === element[index].currentDatasetIndex
              )
            )
            keepShowing.splice(index, 1)
            return
          }
          let multipleElement = []

          activeElement.map(element => {
            multipleElement.push({
              index: element._index,
              currentDatasetIndex: element._datasetIndex
            })
          })

          keepShowing.push(multipleElement)
        } else {
          const elementAtEvent = activeElement[0]
          if (
            keepShowing.some(
              element =>
                element.index === elementAtEvent._index &&
                element.currentDatasetIndex === elementAtEvent._datasetIndex
            )
          ) {
            let index = keepShowing.findIndex(
              element =>
                element.index === elementAtEvent._index &&
                element.currentDatasetIndex === elementAtEvent._datasetIndex
            )
            keepShowing.splice(index, 1)
            return
          }

          keepShowing.push({
            index: elementAtEvent._index,
            currentDatasetIndex: elementAtEvent._datasetIndex
          })
        }
      }
    },
    xAxisCallback(label) {
      const scaleFormat = this.$date.getFormat('days')
      return this.$date
        .formatUtc(+label.split('-')[1], scaleFormat)
        .toUpperCase()
    },
    tooltipCallback({ title, body, index, dataKey, colors }) {
      const format = 'yyyy-MM-dd'
      const tooltipTextIndex = [...new Set(dataKey)].map(data =>
        this.tooltipText.findIndex(text => text.key === data)
      )
      body = tooltipTextIndex.map((value, index) => {
        if (value === -1) {
          return `${this.$numeral(body[0]).format('0,0')} ${this.tooltipText}`
        }
        return `${this.$numeral(body[index]).format('0,0')} ${
          this.tooltipText[value].text
        }`
      })
      colors = tooltipTextIndex.map((value, index) => colors[index])
      title = title
        .split('-')
        .map(timestamp => this.$date.formatUtc(+timestamp, format))
        .join(' - ')
      if (this.interval === 'days') {
        title = title.split(' - ')[0]
      }
      return { title, body, index, colors }
    }
  }
}
</script>
