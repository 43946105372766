<template>
  <div
    v-loading="$actions.pending('company/fetchShipmentsData')"
    class="line-chart overview-shipments-chart"><!--WIP: remove the class "full-width" when top 5 product terms is ready-->

    <div
      v-if="showHeader"
      class="overview-shipments-chart__header">
      <h2>Total shipments {{ dateRangeText }}</h2>
      <span class="total-count">Shipments: <tween-number :value="Math.trunc(shipmentCount)"/></span>
    </div>

    <company-shipments-chart
      :display-empty="displayEmpty"
      :stat="stat"
      :data="datasets"
      :show-loader="false"
      :class="chartClass" />

    <div class="graph-label">
      <div class="legend">
        <span
          v-for="(key, index) in legendElements"
          :key="key"
          :style="{
            cursor: 'pointer',
            'text-decoration': visibleDataset.includes(key) ? 'none' : 'line-through'
          }"
          class="legend-item"
          @click="hideDataset(key)" >
          <label
            :style="{ 'background-color': colors[index] }"
            class="circle"/>
          {{ getLegendLabel(key) }}
        </span>
      </div>
      <div class="data-checkbox-wrapper">
        <hide-incomplete-data-checkbox/>
        <autoscale-axis/>
      </div>
    </div>
  </div>
</template>

<script>
import TweenNumber from '~/components/common/TweenNumber'
import { mapState, mapGetters } from 'vuex'
import CompanyShipmentsChart from '~/components/company/CompanyShipmentsChart'
import HideIncompleteDataCheckbox from '~/components/search/HideIncompletedataCheckbox'
import AutoscaleAxis from '~/components/search/AutoscaleAxis'
import { chart } from '~/config/config'

export default {
  name: 'CompanyShipmentsGraph',
  components: {
    HideIncompleteDataCheckbox,
    TweenNumber,
    CompanyShipmentsChart,
    AutoscaleAxis
  },
  props: {
    showHeader: {
      type: Boolean,
      default: true
    },
    chartClass: {
      type: String,
      default: ''
    },
    displayEmpty: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visibleDataset: []
    }
  },
  computed: {
    ...mapState('company', ['stat', 'shipmentsData']),
    ...mapGetters('company/overview', ['dateRangeText']),
    shipmentCount() {
      return this.shipmentsData[this.stat].totalCount
    },
    legendElements() {
      if (this.stat === 'all') {
        return Object.keys(this.shipmentsData).reduce((keys, key) => {
          if (
            key !== 'all' &&
            (this.displayEmpty || this.shipmentsData[key].totalCount > 0)
          ) {
            keys.push(key)
          }

          return keys
        }, [])
      }
      return [this.stat]
    },
    colors() {
      return chart.line.colors
    },
    datasets() {
      if (this.stat === 'all') {
        if (this.merge) {
          return this.getMergedChartCounts()
        }
        return Object.keys(this.shipmentsData).reduce((counts, key) => {
          if (
            key !== 'all' &&
            (this.displayEmpty || this.shipmentsData[key].totalCount > 0)
          ) {
            const { shipments } = this.shipmentsData[key]
            counts.push({
              key: key,
              data: shipments.map(({ count }) => count),
              visible: this.visibleDataset.includes(key)
            })
          }
          return counts
        }, [])
      }
      return [
        {
          key: this.stat,
          data: this.shipmentsData[this.stat].shipments.map(
            ({ count }) => count
          ),
          visible: this.visibleDataset.includes(this.stat)
        }
      ]
    }
  },
  mounted() {
    this.resetVisibleDataset()
  },
  methods: {
    getLegendLabel(key) {
      switch (key) {
        case 'shipper':
          return 'as Importer'
        case 'consignee':
          return 'as Supplier'
        case 'internal':
          return 'Internal Shipments'
      }
    },
    hideDataset(key) {
      const index = this.visibleDataset.indexOf(key)

      if (index === -1) {
        this.visibleDataset.push(key)
      } else {
        this.visibleDataset.splice(index, 1)
      }
    },
    resetVisibleDataset() {
      this.visibleDataset = Object.keys(this.shipmentsData)
    }
  }
}
</script>
