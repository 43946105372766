import * as randomColor from 'randomcolor'

class Colors {
  static hslToRgb({ h, s, l }) {
    h /= 360

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q

    const third = 1 / 3
    const [r, g, b] = [h + third, h, h - third].map(color => {
      if (color < 0) {
        color++
      }
      if (color > 1) {
        color--
      }
      if (color < 1 / 6) {
        color = p + (q - p) * 6 * color
      } else if (color < 0.5) {
        color = q
      } else if (color < 2 / 3) {
        color = p + (q - p) * 6 * (2 / 3 - color)
      } else {
        color = p
      }
      return Math.round(color * 255)
    })

    return { r, g, b }
  }

  static rgbToHex({ r, g, b }) {
    let hex = '#'
    const array = [r, g, b]
    array.forEach(function(value) {
      if (value < 16) {
        hex += 0
      }
      hex += value.toString(16)
    })
    return hex
  }

  static hexToRgb(hex) {
    if (typeof hex !== 'string') throw new Error(`hex must be a string: ${hex}`)
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b
    })

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : null
  }

  static hex(str, luminosity = 'bright') {
    return randomColor({ seed: str, luminosity })
  }

  static rgbToHsl({ r, g, b }) {
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b)
    let h,
      s,
      l = (max + min) / 2

    if (max === min) {
      h = s = 0 // achromatic
    } else {
      const d = max - min
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0)
          break
        case g:
          h = (b - r) / d + 2
          break
        case b:
          h = (r - g) / d + 4
          break
      }
      h /= 6
    }

    s = s * 100
    s = Math.round(s)
    l = l * 100
    l = Math.round(l)
    h = Math.round(360 * h)
    return { h, s, l }
  }

  static hslToHex({ h, s, l }) {
    return Colors.rgbToHex(Colors.hslToRgb({ h, s, l }))
  }

  static hexToHsl(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

    const r = parseInt(result[1], 16) / 255
    const g = parseInt(result[2], 16) / 255
    const b = parseInt(result[3], 16) / 255
    return Colors.rgbToHsl({ r, g, b })
  }
}

export default Colors
