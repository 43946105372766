<template>
  <data-viewer
    :parent-name="parentName"
    :is-visible="isVisible"
    :loading="loading"
    :data="data"
    :headers="headers"
    :sort-field="sortField"
    :sort-order="sortOrder"
    :total="total"
    :limit="limit"
    :current-page="currentPage"
    :page-limit="pageLimit"
    :shipments="shipments"
    :has-checkboxes="hasCheckboxes"
    :show-custom-view="showCustomView"
    :disabled-column-resizer="disabledColumns"
    :floating-scrollbar-options="floatingScrollbarOptions"
    :options="{
      showSearchIcon: false,
      showCards: false,
      showCardsIcon: false,
      showFullScreenIcon: showFullScreenIcon
    }"
    :table-wrapper-active-class="[8, 9].includes(FirstLoginTour.currentStep) && FirstLoginTour.show"
    :table-class="tableClass"
    :class="{ 'active': [0, 5].includes(FirstLoginTour.currentStep) && FirstLoginTour.show }"
    class="first-login-tour-0 first-login-tour-5 shipments-data-viewer"
    @view-modal="viewModal"
    @sort="$emit('sort', $event)"
    @limit="$emit('limit', $event)"
    @page="$emit('page', $event)"
    @rows-selected="$emit('rows-selected', $event)">
    <!--Header slot: Template Selection -->
    <div
      slot="headerLeft"
      class="data-viewer__header">
      <div class="view-title">
        <h1 class="transform-none">Shipment results {{ dateRangeText }}</h1>
      </div>
    </div>
  </data-viewer>
</template>
<script>
import { mapState } from 'vuex'
import DataViewer from './DataViewer/DataViewer'
import dataViewerProps from './DataViewer/data-viewer-props'
import BookmarkPopover from '~/components/data-viewers/DataViewer/DataViewerHeader/BookmarkPopover'
import { numericalFields } from '~/utils/numericalFields'

export default {
  name: 'ShipmentsDataViewer',
  components: {
    DataViewer,
    BookmarkPopover
  },
  props: {
    ...dataViewerProps,
    shipments: {
      type: Array,
      required: true
    },
    hasBookmark: {
      type: Boolean,
      default: true
    },
    dateRangeText: {
      type: String,
      default: ''
    },
    showCustomView: {
      type: Boolean,
      default: true
    },
    showCards: {
      type: Boolean,
      default: true
    },
    showCardsIcon: {
      type: Boolean,
      default: false
    },
    showFullScreenIcon: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('views', ['activeShipmentsView', 'fields']),
    ...mapState('tours', ['FirstLoginTour']),
    ...mapState(['country', 'tradeType']),
    headers() {
      const fields = this.activeShipmentsView.items.map(
        this.makeHeaderFromField
      )

      return [
        {
          id: 'index',
          text: '#'
        },
        ...fields
      ]
    },
    disabledColumns() {
      return this.hasCheckboxes ? [0, 1] : [0]
    },
    tableClass() {
      return {
        'active-8': this.FirstLoginTour.currentStep === 8,
        'active-9': this.FirstLoginTour.currentStep === 9
      }
    },
    data() {
      return this.shipments.map((shipment, index) => {
        const shipmentLink = `/${this.country}/${this.tradeType}/shipment/${
          shipment.ShipmentId
        }`
        shipment.index = index + 1 + this.pageLimit * (this.currentPage - 1)
        const isChecked = false

        const data = this.headers.map(field => {
          const { id } = field
          let link, className, tooltip, tdClassName
          let value = shipment[id]

          let billOfLading = null
          if (typeof value !== 'number' || id === 'index') {
            if (!value) {
              value = numericalFields.includes(id) ? 0 : '----'
            } else {
              switch (id) {
                case 'index':
                  link = shipmentLink
                  break
                case 'ProductDescription':
                  tdClassName = 'prod-desc'
                  break
                case 'BillOfLading':
                  const masterShipmentLink = `/${this.country}/${
                    this.tradeType
                  }/shipment/${shipment['MasterBillOfLading']}`
                  billOfLading = {
                    link: shipmentLink,
                    text: value,
                    billTypeCode: shipment['BillTypeCode'],
                    masterBillOfLadingLink: shipment['MasterBillOfLading']
                      ? masterShipmentLink
                      : null,
                    masterBillOfLading: shipment['MasterBillOfLading']
                  }
                  link = shipmentLink
                  className = `p-desc ${
                    index === 0 ? 'first-login-tour-8' : ''
                  }`
                  break
                case 'Consignee':
                case 'Shipper':
                case 'Supplier':
                  link = `/company/${shipment[id + 'Id']}/overview`
                  tdClassName = 'company-name'
                  className = `p-${id.toLowerCase()} ${
                    index === 0 ? 'first-login-tour-9' : ''
                  }`
                // case 'lastAddress':
                //   tooltip = value.fullAddress
                //   value = value.shortAddress || 'City/State Unavailable'
              }
            }
          }

          if (shipment.Highlight && shipment.Highlight[id]) {
            value = shipment.Highlight[id][0]
          }

          const cell = { text: value }
          if (link) cell.link = link
          if (className) cell.class = className
          if (tdClassName) cell.tdClass = tdClassName
          if (tooltip) cell.tooltip = tooltip
          if (billOfLading) cell.billOfLadingObject = billOfLading
          return cell
        })

        return { link: shipmentLink, isChecked: isChecked, data }
      })
    }
  },
  methods: {
    makeHeaderFromField(id) {
      const { displayName: text, searchable, sortable } = this.fields[id]
      return { id, text, searchable, sortable }
    },
    viewModal(open) {
      this.$emit('view-modal', open)
    }
  }
}
</script>

<style lang='scss'>
.download-results-form {
  .popper__arrow {
    left: 33% !important;
  }
}
</style>
