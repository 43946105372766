<template>
  <el-popover
    ref="popover"
    :disabled="(!hasData || isDisabled)"
    :value="isOpen && isActive"
    placement="bottom"
    popper-class="bookmark-popover with-footer"
    @show="selectInput"
    @hide="bookmarkName = currentBookmark.name">
    <div class="popover-content">
      <div class="pop-over-default">
        <h2>Bookmark Added</h2>
        <fieldset>
          <h3>Name</h3>
          <input
            ref="bookmarkNameInput"
            v-model="bookmarkName"
            :class="{ error: maxCharReached }"
            :disabled="isCreatingBookmark"
            type="text"
            @paste="validatePaste"
            @keypress="validateKeypress"
            @keyup.enter="editBookmark">
        </fieldset>
      </div>
    </div>
    <div class="popover-footer flex-container justify-right">
      <button
        :class="{ disabled: isCreatingBookmark }"
        class="btn btn-text secondary flex-items"
        @click="deleteBookmark">Remove</button>
      <button
        :class="{ disabled: isCreatingBookmark }"
        class="btn btn-primary flex-items"
        @click="editBookmark">Save</button>
        <!-- <nuxt-link
        class="btn-default btn-text flex-items"
      to="bookmarks">More</nuxt-link>-->
    </div>
    <div slot="reference">
      <el-tooltip content="Add Bookmark">
        <i
          :class="{ active: isActive, disabled: (!hasData || isDisabled) }"
          class="bookmark-icon"
          @click="addBookmark"/>
      </el-tooltip>
    </div>
  </el-popover>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// @TODO repeating import
import { characterLimit } from '~/config/config'

export default {
  name: 'BookmarkPopover',
  props: {
    hasData: {
      type: Number,
      default: 0
    },
    isDisabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isActive: false,
      isOpen: false,
      bookmarkName: '',
      bookmarkId: '',
      maxCharReached: false
    }
  },
  computed: {
    ...mapState('search', ['currentBookmark']),
    isCreatingBookmark() {
      return this.$actions.pending('search/createBookmark')
    }
  },
  watch: {
    currentBookmark(bookmark) {
      if (bookmark.name) {
        this.bookmarkId = bookmark.id
        this.bookmarkName = bookmark.name
        this.isActive = true
        return
      }

      this.bookmarkId = ''
      this.bookmarkName = ''
      this.isActive = false
    }
  },
  mounted() {
    if (this.currentBookmark.name) {
      this.bookmarkId = this.currentBookmark.id
      this.bookmarkName = this.currentBookmark.name
      this.isActive = true
    }

    document.addEventListener('keyup', this.closePopoverOnEsc)
  },
  destroyed() {
    document.removeEventListener('keyup', this.closePopoverOnEsc)
  },
  methods: {
    ...mapActions('search', [
      'createBookmark',
      'updateBookmark',
      'removeBookmark'
    ]),
    closePopover() {
      this.$refs.popover.doClose()
    },
    closePopoverOnEsc(event) {
      if (event.key === 'Escape') this.closePopover()
    },
    async addBookmark() {
      if (this.isDisabled || !this.hasData || this.isActive) return

      try {
        const defaultName = `bookmark-${new Date().getTime()}`
        // Setting the active and name right away so the user
        // won't feel the lag from saving the bookmark in the background
        this.bookmarkName = defaultName
        this.isActive = true
        await this.createBookmark(defaultName)
        this.selectInput()
      } catch (error) {
        this.bookmarkName = ''
        this.isActive = false
        this.closePopover()
      }
    },
    async editBookmark() {
      try {
        this.closePopover()
        if (
          !this.isActive ||
          !this.bookmarkName.trim() ||
          this.bookmarkName.trim() === this.currentBookmark.name.trim() ||
          this.isCreatingBookmark
        ) {
          return
        }

        await this.updateBookmark({
          id: this.bookmarkId,
          name: this.bookmarkName
        })
      } catch (err) {
        this.bookmarkName = this.currentBookmark.name

        throw err
      }
    },
    async deleteBookmark() {
      try {
        this.closePopover()
        if (!this.isActive || !this.bookmarkId || this.isCreatingBookmark) {
          return
        }

        this.isActive = false
        await this.removeBookmark(this.bookmarkId)
      } catch (err) {
        this.isActive = true

        throw err
      }
    },
    selectInput() {
      this.$nextTick(() => {
        this.$refs.bookmarkNameInput.focus()
        this.$refs.bookmarkNameInput.select()
      })
    },
    validateKeypress(event) {
      if (this.bookmarkName.length >= characterLimit) {
        event.preventDefault()
        this.maxCharReached = true
        setTimeout(() => {
          this.maxCharReached = false
        }, 500)
      }
    },
    validatePaste(event) {
      const pastedText = event.clipboardData.getData('text')
      if (this.bookmarkName.length + pastedText.length > characterLimit) {
        event.preventDefault()
        this.maxCharReached = true
        setTimeout(() => {
          this.maxCharReached = false
        }, 500)
      }
    }
  }
}
</script>
<style lang="scss" src="~/assets/scss/sections/bookmark/bookmark.scss"/>
